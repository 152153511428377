import { Container, Typography, Link, Box, Grid2, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const socialLinks = [
  { icon: <FacebookIcon />, alt: 'Facebook' },
  { icon: <TwitterIcon />, alt: 'Twitter' },
  { icon: <LinkedInIcon />, alt: 'LinkedIn' },
  { icon: <YouTubeIcon />, alt: 'YouTube' },
];

const Footer = () => {
  return (
    <footer>
      <Container maxWidth="100%"
        sx={{
          padding: 4,
         background: 'black',
          color: 'white',
          pl: 6
        }}
      >
        <Box mb={4}>
          {/* FusionERP Section Below */}
          <Box mt={4} sx={{ ml:6}}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Why Choose FusionERP?
            </Typography>
            <Typography variant="h5" sx={{ mt: 1,fontWeight:'bold',mb:2,color:'white'}}>
             Streamline your business process with <span style={{color:'#12715B'}}>FusionERP</span> 
            </Typography>
           
            <Button
              sx={{
                  bgcolor: 'white',
                  color: '#12715B',
                  padding: '12px 20px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  borderRadius: '7px',
                  width: { xs: '100%', md: 'auto' },
              }}
              href="/contactus"
            >
              Contact Us
            </Button>
          </Box>

          <Grid2 container spacing={2} justifyContent="space-between" sx={{ mt: 2.5 ,ml:6}}>
            {/* FusionERP Company Information */}
            <Grid2 item xs={12} sm={6} md={3} lg={2} sx = {{color:'white'}}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                FusionERP
              </Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>Ciphercode Tech Solutions Pvt. Ltd.</Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                HQ: No. 6, IndiQube Delta, Opp Agara Lake,
              </Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                14th Main Road, Sector 5, HSR Layout, Bangalore 560034, India.
              </Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                EU: Boulevard De La Sauveniere 121, 4000 Liege, Belgium.
              </Typography>
              <Typography sx={{ fontSize: '14px', mt: 1 }}>
                Ph: +32 495 29 76 45.
              </Typography>
            </Grid2>

            {/* Products Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2, color:'white' }}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                Products
              </Typography>
              <Link href="/erp" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP ERP</Link>
              <Link href="/crm" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP CRM</Link>
              <Link href="/hrms" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP HRMS</Link>
              <Link href="/health-care" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP HealthCare</Link>
              <Link href="/assets" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>FusionERP Asset Management</Link>
            </Grid2>

            {/* Pricing Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold',color: 'white' }}>
                Pricing
              </Typography>
              <Link href="/pricing" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>Plans</Link>
            </Grid2>

            {/* Resource Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold',color: 'white'}}>
                Resource
              </Typography>
              <Link href="/about-us" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>About Us</Link>
            </Grid2>

            {/* Get Help Section */}
            <Grid2 item xs={12} sm={6} md={3} lg={2}>
              <Typography variant="h5" sx={{ fontSize: '14px', mt: 1, fontWeight: 'bold',color: 'white' }}>
                Get Help
              </Typography>
              <Link href="/chat-support" sx={{ textDecoration: 'none', fontSize: '14px', color: 'white' }}>Chat Support</Link>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
