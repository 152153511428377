import React, { useState,useEffect } from 'react'
import Container from '@mui/material/Container';
import { Box, Stack, Typography, TextField, Button, InputAdornment, IconButton, Grid2 } from '@mui/material';
import homeimage from "../Images/Fusionerplogo.jpeg";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import image1 from '../Images/HRMS.png';
import image2 from '../Images/ERP(1).jpg';
import image3 from '../Images/CRM.jpg';
import image4 from '../Images/HealthCare.png';
import image5 from '../Images/Assets.svg';
import Footer from './Footer';
import Profile from './Profile';
import talking from "../Images/talking.svg";
import chatting from "../Images/chatting.svg";
import DarkLightImage from './DarkLightImage';
import { Snackbar } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

function LandingPage() {

    const sections = [
        {
            id:1,
            image: image3,
            heading: (
                <>
                    Optimize your entire business operations with -{' '}
                    <span style={{ color: '#12715B' }}>FusionERP</span>
                </>
            ),
            description: "Get your business up and running in seconds with an efficient ERP system. FusionERP assists businesses by efficiently managing and organizing their everyday operations through HR, accounting, automation and more.",
            buttonText: "Read more",
            buttonLink: "/erp",
            icon: <CloudDownloadIcon style={{ color: 'whitesmoke', fontSize: '24px' }} />,
            reverseOrder: false, 
        },
        {
            id:2,
            image: image2,
            heading: (
                <>
                    Boost your sales & grow your revenue -{' '}
                    <span style={{ color: '#12715B' }}>FusionERP CRM</span>
                </>
            ),
            description: "Time is a crucial element in our lives, helping us to structure and organize our daily activities into good habits...",
            buttonText: "Read more",
            buttonLink: "/crm",
            icon: <TollOutlinedIcon style={{ color: 'whitesmoke', fontSize: '24px' }} />,
            reverseOrder: true, 
        },
        {
            id:3,
            image: image1,
            heading: (
                <>
                    Optimize your everyday HR functions with -{' '}
                    <span style={{ color: '#12715B' }}>FusionERP HRMS</span>
                </>
            ),
            description: "Fusion HRMS offers a complete solution for managing human resources,including payroll, attendance, and employee information, all in one place...",
            buttonText: "Read more",
            buttonLink: "/hrms",
            icon: <TollOutlinedIcon style={{ color: 'whitesmoke', fontSize: '24px' }} />,
            reverseOrder: true, 
        },
        {
            id:4,
            image: image4,
            heading: (
                <>
                    FusionERP Provides Healthcare Implementation, consulting -{' '}
                    <span style={{ color: '#12715B' }}>HealthCare</span>
                </>
            ),
            description: "Time is a crucial element in our lives, helping us to structure and organize our daily activities into good habits...",
            buttonText: "Read more",
            buttonLink: "/hrms",
            icon: <TollOutlinedIcon style={{ color: 'whitesmoke', fontSize: '24px' }} />,
            reverseOrder: true, 
        },
        {
            id:5,
            image: image5,
            heading: (
                <>
                    Only Scheduling tool you need - FusionERP -{' '}
                    <span style={{ color: '#12715B' }}>Asset Management</span>
                </>
            ),
            description: "Helps businesses efficiently manage and maintain their assets,ensuring optimal utilization and minimizing downtime...",
            buttonText: "Read more",
            buttonLink: "/hrms",
            icon: <TollOutlinedIcon style={{ color: 'whitesmoke', fontSize: '24px' }} />,
            reverseOrder: true, 
        },
    ];

    

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarTitle, setSnackbarTitle] = useState('');


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        mobileNo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch('https://admin.fusionerp.in/api/method/agent.api.create_doc.create_crm_lead_from_contact_us', {
                cache: 'no-cache',
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.message) {
                if (result.message.message.success === "Email ID added successfully") {
                    setSnackbarMessage("Email ID added successfully");
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);

                } else if (result.message && result.message.message && result.message.message.error) {
                    let errorMessage = result.message.message.error;
                    setSnackbarSeverity(errorMessage === 'Email ID already exists' ? 'warning' : 'error');
                    setSnackbarTitle(errorMessage === 'Email ID already exists' ? 'Warning' : 'Error');
                    setSnackbarMessage(errorMessage === 'Email ID already exists' ? 'You already requested, please check your email.' : errorMessage);
                    setSnackbarOpen(true);
                }
            }

        } catch (error) {
            console.error('Error submitting form:', error);
            setSnackbarMessage('An error occurred. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarTitle('Error');
            setSnackbarOpen(true);
        }
    };
    return (
        <>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    height: { xs: '110vh', md: '100vh' },
                    pt: 5,
                }}
            >

                <Stack
                    direction={{
                        xs: "column",
                        md: "row"
                    }}
                    spacing={0}
                    sx={{
                        justifyContent: 'space-between',
                        mb:4
                    }}
                >
                    <Grid2
                        sx={{
                            flex: 1,
                            pt: { xs: 0, md: 7 },
                            ml: { xs: 0, md: 7 },
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '2.6rem',
                                    md: '4rem',
                                    lg: '4rem',
                                    xl: '4rem'
                                },
                                lineHeight: '1em',
                                letterSpacing: '-0.16rem',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                mb: 5,
                            }}
                        >
                            Software that cover <br />
                            <span style={{ color: 'rgb(18 113 91)', fontWeight: 'bold' }}>all aspects</span> of your
                            <br />growing business.
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: { xs: 'center', md: 'left' },
                                fontSize: { xs: '13px', md: '1.300rem' },
                                opacity: 0.6,
                                mb: 4,
                            }}
                        >
                            Say goodbye to the hassle of managing multiple software <br />
                            and tools for different tasks. FusionERP offers a comprehensive <br />
                            suite of tools that cover all aspects of your business.
                        </Typography>


                        {/* Search box */}
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            spacing={2}
                        >
                            <Box>
                                <form onSubmit={handleSubmit} noValidate>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <TextField
                                            color="success"
                                            variant="outlined"
                                            placeholder="Your email address"
                                            size="small"
                                            value={formData.email}
                                            onChange={handleChange}
                                            name="email"
                                            sx={{
                                                width: 350,
                                                borderRadius: 2,
                                                height: 46,
                                                bgcolor: 'white',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                    height: '100%',
                                                    padding: 0,
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                    '&.Mui-focused': {
                                                        backgroundColor: 'transparent',
                                                        borderColor: '#12715B',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    height: '100%',
                                                    padding: '12px',
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" sx={{ ml: 2 }}>
                                                        <MailOutlineIcon sx={{ color: '#12715B' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            href="/signup"
                                            sx={{
                                                bgcolor: '#12715B',
                                                color: 'white',
                                                padding: '11px 17px',
                                                borderRadius: '8px',
                                                fontSize: '15px',
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                                height: 46,
                                            }}
                                        >
                                            Try it out for free
                                        </Button>
                                    </Stack>
                                </form>

                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    message={snackbarMessage}
                                    action={
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            color="inherit"
                                            onClick={handleCloseSnackbar}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    }
                                />
                            </Box>
                        </Stack>




                        <Typography
                            sx={{
                                mt: { xs: 2, md: 3 },
                                ml: { xs: 0, md: 0.5 },
                                fontSize: {
                                    xs: '0.875rem',
                                    md: '16px'
                                },
                                opacity: '60%',
                                mb: {
                                    xs: 3,
                                    md: 'auto'
                                }
                            }}
                        >
                            We care about your data in our
                            <span
                                style={{
                                    color: '#12715B',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    paddingLeft: '5px'
                                }}
                            >
                                privacy policy

                            </span>
                        </Typography>
                    </Grid2>

                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 5,
                            order: { xs: 1, md: 2 },
                            padding: { xs: 2, md: 4 },
                            borderRadius: '12px',
                            mr: { xs: 2, md: 4 },
                            maxWidth: { xs: '90%', md: '40%' },
                            mx: 'auto',
                            height: { xs: '300px', md: '330px' },

                        }}
                    >
                        <Box
                            component="img"
                            src={homeimage}
                            alt="secondimage"
                            sx={{
                                width: '100%',
                                height: '110%',
                                objectFit: 'contain',
                                borderRadius: '12px',
                                //   backgroundColor: '#F5EEE9',
                            }}
                        />
                    </Box>

                </Stack>


                <Box
                    id = "track-leads"
                    sx=
                    {{
                        textAlign: 'center',
                        justifyContent: 'center',
                        maxwidth: '100%',
                       ion: 'opacity 0.5s ease, transform 0.5s ease',
                    }}
                >

                    <Typography variant='h2'
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '2.2rem', md: '2.8rem' },
                            lineHeight: '1.1em',
                            letterSpacing: '-0.12rem',
                        
                        }}
                    >
                        A Software tools help you keep {' '}
                        <Box
                            component="span"
                            sx={{
                                display: { xs: 'inline', md: 'block' },
                            }}
                        >
                            track of leads
                        </Box>
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: '1rem', md: '14px' },
                            mt: 2,
                            opacity: 0.6,
                            p: { xs: 2, md: 'auto' },
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        Join the thousands of satisfied users and take your business to the next level.{' '}

                    </Typography>
                </Box>
                
                {/* Everything on the first to last images */}
                <Grid2 container spacing={4}>
                {sections.map((section, index) => (
                    <motion.div
                        key={section.id}
                        initial={{ opacity: 0, x: section.reverseOrder ? 100 : -100 }}
                        whileInView={{
                            opacity: 1,
                            x: 0,
                        }}
                        transition={{ duration: 0.8, ease: 'easeOut' }} 
                        viewport={{ once: true, amount: 0.3 }} 
                    >
                    <Box
                        display="flex"
                        flexDirection={{
                        xs: 'column',
                        md: section.id % 2 === 1 ? 'row' : 'row',
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 10, gap: 10, mx: { xs: 2, md: 4 } }}
                    >
                        {/* Content Section */}
                        <Stack
                        flex="1"
                        sx={{
                            order: { xs: 2, md: section.id % 2 === 1 ? 2 : 1 },
                        }}
                        >
                        <Button
                            sx={{
                            borderRadius: '12px',
                            bgcolor: '#12715B',
                            maxWidth: '50px',
                            minWidth: '40px',
                            height: '50px',
                            padding: 0,
                            ml: { xs: 2, md: 8 },
                            mt: { xs: 2, md: 0 },
                            }}
                        >
                            {section.icon}
                        </Button>

                        <Box sx={{ ml: { xs: 2, md: 8 } }}>
                            <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '23px', md: '2.4rem' },
                                lineHeight: '1.1em',
                                letterSpacing: '-0.08rem',
                                fontWeight: 'bold',
                                mt: 5,
                            }}
                            >
                            {section.heading}
                            </Typography>

                            <Typography
                            variant="body1"
                            sx={{
                                mt: 2,
                                fontSize: { xs: '16px', md: '1.095rem' },
                                opacity: '70% !important',
                                textWrap: 'wrap',
                            }}
                            >
                            {section.description}
                            </Typography>

                            <Box sx={{ mt: 2 }}>
                            <Button
                                sx={{
                                p: '7px 15px',
                                bgcolor: 'rgb(18 113 91)',
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                fontSize: '15.5px',
                                borderRadius: '30px',
                                }}
                                href={section.buttonLink}
                            >
                                {section.buttonText} <ArrowForwardIcon sx={{ ml: 0.2 }} />
                            </Button>
                            </Box>
                        </Box>
                        </Stack>

                        {/* Image Section */}
                        <Box
                        flex="1"
                        display={{ xs: 'column', md: 'flex' }}
                        justifyContent="center"
                        sx={{
                            order: { xs: 1, md: section.id % 2 === 1 ? 1 : 2 },
                            backgroundColor: '#F5EEE9',
                            padding: { xs: 2, md: 4 },
                            borderRadius: '20px',
                            mr: { xs: 2, md: 4 },
                            maxWidth: { xs: '90%', md: '38%' },
                            mx: 'auto',
                            height: { xs: '300px', md: '300px' },
                        }}
                        >
                        <Box
                            component="img"
                            src={section.image}
                            alt="image"
                            sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: '12px',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                transition: 'transform 0.3s ease',
                            },
                            }}
                        />
                        </Box>
                    </Box>
                    </motion.div>
                ))}
                </Grid2>




                {/* Last Index Ends*/}
                <Box sx={{ mt: 10, mb: 10, position: 'relative' }}>
                    <Profile />
                </Box>

                {/* Last Index 2 */}
                <Box
                    sx={{
                        minHeight: '70vh',
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: '#F5EEE9',
                        borderRadius: '16px',
                        m: '5%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        {/* Left Image */}
                        <Box
                            sx={{
                                flex: 1,
                                height: '100%',
                                display: { xs: 'none', sm: 'block' },
                                justifyContent: 'flex-start',

                            }}
                        >
                            <img
                                src={talking}
                                alt="talking"
                                style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    width: '80%',
                                    clipPath: 'inset(0% 0% 0% 0%)',
                                    marginLeft: '-30px',
                                }}
                            />


                        </Box>

                        <Box>
                            <DarkLightImage />
                        </Box>

                        {/* Center Content */}
                        <Box
                            sx={{
                                flex: 2,
                                textAlign: 'center',
                                px: { xs: 2, md: 20 },
                                py: { xs: 2, md: 4 },
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    fontSize: { xs: '1.5rem', md: '2rem' },
                                    lineHeight: '1em',
                                    letterSpacing: '-0.07rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                No long-term contracts. No catches. Simple.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '1rem', md: '1.25rem' },
                                    opacity: '70%',
                                    mt: 2,
                                }}
                            >
                                Start your 30-day free trial. Cancel anytime.
                            </Typography>
                            <Box
                                sx={{
                                    mt: 4,
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'center',
                                    gap: 2,
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    startIcon={<PlayCircleOutlineIcon />}
                                    sx={{
                                        bgcolor: 'white',
                                        color: 'black',
                                        border: '1px solid black',
                                        textTransform: 'none',
                                        padding: '8px 20px',
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                >
                                    View Demo
                                </Button>
                                <Button
                                    sx={{
                                        bgcolor: '#12715B',
                                        color: 'white',
                                        padding: '12px 20px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        borderRadius: '7px',
                                        width: { xs: '100%', md: 'auto' },
                                    }}
                                    href="/pricing"
                                >
                                    Start free trial
                                </Button>
                            </Box>
                            <Typography sx={{ mt: 2, fontSize: '13px', opacity: '70%' }}>
                                14-day trial, no credit card required.
                            </Typography>
                        </Box>

                        <Box>
                            <DarkLightImage />
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                height: '100%',
                                display: { xs: 'none', sm: 'block' },
                                justifyContent: 'flex-end',
                                mr: '-80px'


                            }}
                        >

                            <img
                                src={chatting}
                                alt="chatting"
                                style={{
                                    height: '100%',
                                    objectFit: 'cover',
                                    width: '80%',
                                    marginRight: '-50px'
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                {/* Last Index 2 End */}
                <Box>
                    <Footer />
                </Box>
            </Container>
        </>
    );
}

export default LandingPage;
